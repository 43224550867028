.button{
    outline:none;
    box-shadow:none;
    transition:all .15ms ease-in-out;
    display:flex;
    align-items:center;
    font-weight:500;
    border-radius:3px;
    font-size:14px;
    justify-content:center;
    line-height:1.6;
}
.button.smallButton{
    font-size:13px;
    line-height:1.6;
    padding:.30rem .85rem;
    vertical-align:middle;
}
.btnBlock{
    width:100%;
    display:flex;
}
.btnPrimary,.btnPrimary:hover,
.btnPrimary:focus,
.btnPrimary:active,.btnPrimary:visited
{
    color:#fff;
    background-color:var(--primary-color);
    outline:0;
    box-shadow:none;
}

.btnPrimary:hover,
.btnPrimary:focus,
.btnPrimary:active,.btnPrimary:visited
{
    opacity:1;
}


.btnDefault{
    background-color: transparent;
    border: 1px solid #e2e5ec;
    color: #595d6e;
}

.btnDefault:focus, .btnDefault.active,
.btnDefault:active,
.btnDefault:hover {
    outline: 0;
    box-shadow:none;
    color: #595d6e;
    border-color: #e2e5ec;
    background-color: #f4f5f8;
}


.button > svg{
    height:14px;
}

/*.button:disabled{*/
/*    background-color:#c9c9d2 !important;*/
/*    color:var(--color-black);*/
/*}*/
