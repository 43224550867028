
.product-detail-carousel .swiper-button-next,
.product-detail-carousel .swiper-button-prev{
    color:#fff;
    background-color: rgba(0,0,0,0.6);
    height:30px;
    width:30px;
    border-radius: 50%;
}

.product-detail-carousel .swiper-button-next:after,
.product-detail-carousel .swiper-button-prev:after
{
    font-size:0.9rem;
    font-weight: 700;
}