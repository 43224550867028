.content-box-profile{
    display: flex;
    flex-direction: column;
    background-color:#fff;
    padding-bottom:60px;
    position: relative;
    text-align:left;
    align-self: center;
    box-shadow: var(--box-shadow-3);
    border-radius: 1px;
    overflow: hidden;
    max-width:350px;
    margin:15px auto 0 auto;
}

.content-box-profile{
    padding-left: 0;
    padding-right: 0;
}
.content-box-profile > a{
    display: inline-block;
    height: 250px;
    width: 100%;
    overflow: hidden;
    padding-left:0;
    padding-right:0;
    margin-bottom: 10px;
}

.content-box-profile > br{
    display: none;
}
.content-box-profile > *{
    padding-left: 15px;
    padding-right: 15px;
}

.content-box-profile > a img{
    min-width:100%;
    height:100%;
}

.content-box-profile > a img.img-thumbnail{
    border: none;
    padding:0;
    border-radius: 0;
}

.content-box-profile .team-member-name-imgtop{
    font-size:16px;
    text-align: left;
    margin-top:15px;
    color: var(--color-black);
}

.content-box-profile .team-member-name-imgtop small a{
    position: absolute;
    bottom: 20px;
    background-color: var(--primary-color);
    color:#fff;
    transition: all .4s ease-out;
    padding: 10px 10px ;
    display:flex;
    align-items: center;
    border-radius: 2px;
    justify-content: center;
    font-size: 14px;
}

.content-box-profile p{
    color: var(--color-dark-grey) !important;
    margin-bottom: 10px !important;
    font-size:15px !important;
}