
.home-page-banner {
    width: 100%;
    height: 100vh;
    max-height:600px;
    overflow: hidden;
    position:relative;
}
.home-page-banner.home-page-mini-banner{
    height: 550px;
}
.home-page-banner img{
    width: 100%;
    height:auto;
    min-height: 100%;
    
}
.home-page-banner .overlay{
    position:absolute;
    text-align: center;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.4);
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
}

.home-page-banner.home-page-mini-banner .overlay{
    justify-content: flex-end;
    padding: 90px;
}

.home-page-banner .overlay h1{
    font-weight:800;
    color:#fff;
    font-size: 5.5rem;
    letter-spacing: 10px;
    margin-bottom: 0;
}
.overlay h1,
.overlay h2,
.overlay h3,
.overlay h4,
.overlay h5 {
    color:#ffffff;
    text-shadow: 2px 0px 5px rgba(0,0,0,1);
    -webkit-animation: slide-in-fwd-center 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-fwd-center 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-fwd-center {
    0% {
      -webkit-transform: translateZ(-1400px);
              transform: translateZ(-1400px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-fwd-center {
    0% {
      -webkit-transform: translateZ(-1400px);
              transform: translateZ(-1400px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }}

.overlay span{
    color:#ffffff;
    text-shadow: 2px 1px 1px rgba(0,0,0,1);
    padding-left: 20px;
    padding-right: 10px;
    /* TEXT BACKGROUND */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(76, 76, 76, 0.4) 10%,rgba(76, 76, 76, 0.5) 20%, rgba(76, 76, 76, 0.7) 50%, rgba(76, 76, 76, 0.5) 80%, rgba(76, 76, 76, 0.3) 90%, rgba(255, 255, 255, 0) 100%);
    display: inline-block;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s both;
            
}@-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  
.home-page-banner.home-page-mini-banner .overlay h3{
    font-size: 2.8rem;
    font-weight:700;
    letter-spacing: 5px;
    color: #fff;
    margin: 0 10px;
}

.home-page-banner .overlay h5{
    color:#fff;
    font-size: 1.8rem;
    letter-spacing: 10px;
    margin:-10px 0 0;
    text-transform: uppercase;
    font-weight: 400;
}

.home-page-banner.home-page-mini-banner .overlay h6{
    color:#fff;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 1px;
    max-width:600px;
    margin-top: 10px;
}

.home-page-banner .btn-primary,
.home-page-banner .btn-primary:focus {
    
    font-size: 1.5rem;
    letter-spacing:3px;
    text-transform: uppercase;
    background-color:var(--primary-color);
    border:1px solid var(--primary-color);
    border-radius:40px;
    padding:10px 40px;
    margin-top:30px;
    display:flex;
    align-items:center;
    box-shadow: none;
    outline: none;
    
}


.home-page-banner.home-page-mini-banner .overlay .btn-primary
{
    font-size: 1.3rem;
    padding:10px 20px;
    margin-top:15px;
    border-radius:2px;
}



.home-page-section .homepage-image-card {
    height:600px;
    position: relative;
    overflow:hidden;
    color:#fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.home-page-section .homepage-image-card img{
    width: 100%;
    height: auto;
    min-height:120%;
}



.home-page-section .homepage-image-card h2{
    font-weight: 700;
    font-size:3rem;
    margin-bottom:0;
    text-align: center;
}

.home-page-section .homepage-image-card h5{
    margin:25px 0;
    font-weight: 400;
    font-size:1.15rem;
    padding:15px 15px;
    letter-spacing:1px;
    color:var(--color-dark-grey);
    background-color:rgba(255, 255, 255, 0.7);
    text-align:center;
}

.home-page-section .btn-white
{
    color:var(--color-dark-grey);
    background: linear-gradient(to left, rgba(255,255,255,0.8) 50%,  rgba(0,0,0,1) 50%) right;
    background-size: 210%;
    transition: all .4s ease-out;
    padding: 10px 25px;
    box-sizing: border-box;
    text-transform: uppercase;
    display:flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0;
    font-size: 18px;
    outline: none;
    box-shadow: none;
}

.home-page-section .btn-white.read-more:after
{
    display: inline-block;
    content: "";
    height: 15px;
    width: 11px;
    margin-left:10px;
    background-position: center center;
    transition: all .4s ease-out;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='arrow-right' class='svg-inline--fa fa-arrow-right fa-w-14 ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23616161' d='M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z'%3E%3C/path%3E%3C/svg%3E");
}


.home-page-section .btn-white:hover {
    background-position: left;
    color:#fff;
}


.home-page-section .btn-white.read-more:hover:after {
    transition: all .4s ease-out;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='arrow-right' class='svg-inline--fa fa-arrow-right fa-w-14 ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23fff' d='M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z'%3E%3C/path%3E%3C/svg%3E");
}

.home-page-section .btn-white svg{
    height: 15px;
    width: 10px;

}

.home-page-section .homepage-image-card-2{
    height: 450px;
    overflow:hidden;
    position: relative;
    margin-bottom: 30px;
    
}

.home-page-section .homepage-image-card-2 img{
    width:100%;
    height: auto;
    min-height:100%;
}

.home-page-section .homepage-image-card-2 .overlay,
.home-page-section .homepage-image-card .overlay
{
    position: absolute;
    /*OVERLAY COLOR*/
    background-color:rgba(106, 106, 106, 0.05);
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding:20px 10px;
}

.home-page-section .homepage-image-card .overlay{
    padding: 30px 40px;
}


.home-page-section .homepage-image-card-2 .overlay.content-end{
    padding-bottom:50px;
    justify-content:flex-end;
}

.home-page-section .homepage-image-card-2 h3{
    font-size:2rem;
    text-align: center;
    font-weight:700;
    color:#fff;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin-bottom: 15px;
}

@media (max-width: 575px) {
    .home-page-section .homepage-image-card h2{
        font-size:2.5rem;
    }
}

.home-page-section-new .main-card{
    height: 100%;
}

.home-page-section-new .homepage-image-card-2{
    margin-bottom: 0px;
}

.home-page-section-new .homepage-image-card-2 .btn-primary{
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    padding: 10px 40px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    box-shadow: none;
    outline: none;
    -webkit-box-shadow: 0px 5px 2px 0px rgba(0,0,0,0.42); 
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.42);
    border: 1px solid rgba(0, 0, 0, 0.161);
}
.home-page-section-new .homepage-image-card-2 .btn-primary:hover{
    background-color: rgb(142, 213, 70);
    -webkit-box-shadow: 0px 5px 2px 0px rgba(97, 97, 97, 0.42); 
    box-shadow: 0px 3px 5px 0px rgba(97, 97, 97, 0.42);
    border: 1px solid rgba(0, 0, 0, 0.161);
    opacity: 1;
}

.home-page-section-new .overlay  h5{
    color: #fff;
    font-size: 1.8rem;
    letter-spacing: 5px;
    margin: -10px 0 0;
    text-transform: uppercase;
    font-weight: 400;
}

.home-page-section-new .img-card-middle{
    height: 450px;
}

.home-page-section-new .img-card-bottom{
    height: 450px;
}

.home-page-section-new .img-card-middle img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.home-page-section-new h4,
.home-page-section-new h6 {
    text-align: center;
    font-weight: 700;
    color: #fff;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    margin-bottom: 15px;
}

.home-page-section-new .overlay h2 {
    font-weight: 800;
    color: #fff;
    font-size: 4rem;
    letter-spacing: 10px;
}

.home-page-section-new .overlay h3 {
    font-size: 2.5rem;
}

@media (max-width: 676px) {
    .home-page-section-new .overlay h2 {
        font-size:2rem;
        letter-spacing: 5px;
    }

    .home-page-section-new .overlay h5 {
        font-size: 1rem;
        letter-spacing: 2px;
    }

    .home-page-section-new .overlay h3 {
        font-size: 1.5rem;
    }

    .home-page-section-new .main-card {
        height: 70vh;
    }
}
