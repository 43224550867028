html,
body {
  position: relative;
  min-height: 100%;
  height: 100%;
  margin: 0;
  line-height: 1.65;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
  min-width: 320px;
  direction: ltr;
  font-feature-settings: 'kern';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: #fbfbfb;
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  font-feature-settings: "kern";
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
strong:empty{
  display: none !important;
}
ul{
  margin-bottom:0;
  padding-left:0;
}


:root {
  --color-black:#1e1e27;
  --color-text-black:#191f2e;
  --color-off-white:#b5aec4;
  --color-border-light:#33333b;
  --color-grey:#eceff6;
  --primary-color:#7dbc40;
  --color-white:#fff;
  --color-light-grey:#b1b8c3;
  --color-dark-grey:#616161;
  --box-shadow-2:  0 1px 2px rgba(0,0,0,0.2);
  --box-shadow-3:  0 1px 2px rgba(0,0,0,0.3);
  --box-shadow-4:  rgba(0,0,0,0.2) 0px 0px 4px -1px,
  rgba(0,0,0,0.2) 0px 0px 5px, rgba(0,0,0,0.2) 0px 0px 10px;
}

@media (min-width:1200px)
{
  .container {
    max-width: 1140px;
  }
}
a{
  cursor: pointer;
  transition: all .15ms ease-out;
}
li a{
  color:var(--color-black);
}


.modal .modal-content{
  border: none;
  -webkit-box-shadow: 0 0 0 1px #dadce0;
  box-shadow: 0 0 0 1px #dadce0;
  border-radius: 4px;
}
.breadcrumb-arrow{
  fill:var(--color-dark-grey);
}



.item-container .item  h3:empty{
  display:none !important;
}

.form-check-input:checked
{
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.form-check-input:focus{
  box-shadow: none;
   border-color: var(--primary-color); 
}

.text-primary {
  color: var(--primary-color) !important;
}

.select__control{
  border-color: hsl(0, 0%, 80%) !important;
  box-shadow: none !important;
}
.select__control:hover{
  border-color: hsl(0, 0%, 80%) !important;
  box-shadow: none !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
  z-index: 1200!important;
}

.img-responsive{
  max-width: 100%;
  height: auto;
}

@media (min-width:1400px) {
  .container-xxl {
    max-width: 1560px;
  }
}

.btn-primary,a.btn-primary{
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover,a.btn-primary:hover{
  color: #fff;
  opacity: 0.9;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-secondary:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.text-primary {
  color: var(--primary-color) !important;
}

a.link {
  color: var(--primary-color);
}

a.link:hover {
  text-decoration: underline;
}

a.btn-primary,
a.btn-primary:active,
a.btn-primary:hover,
a.btn-secondary,
a.btn-secondary:active,
a.btn-secondary:hover,
a.btn-dark,
a.btn-dark:hover,
a.btn-dark:active
{
  color:#fff !important;
}
