@media (min-width: 576px) {
    .checkoutMessageModalContent{
        max-width: 350px !important;
    }
}
.checkoutMessageOverlay{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    opacity: 0;
    z-index: -99;
    transform: scale(0.9);
    transition: all .15s ease-in;
}
.showConfirmation.checkoutMessageOverlay
{
    transform: scale(1);
    opacity: 1;
    z-index: 9999;
}