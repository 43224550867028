/* item containers */
/* -- MinHeight-- */
/* test items */

.item-container,
.items-container,
.data-api-items,
.target-items,
.property-items {
    overflow: hidden;
    margin: 0 -1%;
}
.item-container,
.items-container{
    display: flex;
    flex-flow: wrap;
}
.item {
    display: block;
    float: left;
    width: 31%;
    padding: 5px;
    margin: 5px;
    /*background-color: #F7F7F7;*/
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px !important;
    border-color: #000 !important;
}



.item-0 {  }
.item-1 {  }
.item-2 {  }
.item-3 {  }
.item-4 {  }
.item-5 {  }
.item-6 {  }
.item-7 {  }
.item-8 {  }
.item-9 {  }
.item-10 {  }
.item-11 {  }

.item-0, .item-1, .item-2, .item-3, .item-4, .item-5, .item-6, .item-7, .item-8, .item-9, .item-10, .item-11 {  }

.item img {
    display: block;
    max-width: 100%;
}

.item p:last-child {
    margin-bottom: 0;
}

.items-container.nested-items-parent .item {
    width: 46%;
}

/* test responsive */
@media only screen and (max-width: 1024px) {
    .item {
        width: 46%;
    }
}

@media only screen and (max-width: 640px) {
    .item {
        width: 99%;
        margin-left: 0;
        margin-right: 0;
    }
}
/*--/MinHeight--*/

/* -- MinHeight2-- */
.items-container2,
.data-api-items,
.target-items,
.property-items {
    overflow: hidden;
    margin: 0 -1%;
}

.item2 {
    display: block;
    float: left;
    width: 47%;
    padding: 5px;
    margin: 5px;
    /*background-color: #F7F7F7;*/
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

}


.item2 img {
    display: block;
    max-width: 40%;
    float:left;
    margin-right: 5px;
}

.item2 p:last-child {
    margin-bottom: 0;
}

.items-container2.nested-items-parent .item2 {
    width: 47%;
}


/* test responsive*/
@media only screen and (max-width: 1024px) {
    .item2 {
        width: 47%;
    }
}

@media only screen and (max-width: 640px) {
    .item2 {
        width: 99%;
        margin-left: 0;
        margin-right: 0;
    }
}
/*--/MinHeight2--*/
/* item containers */

/* Piilota ylimääräiset item-containerien sisältä */
.item-container > p, .item-container2 > p,
.item-container > br, .item-container2 > br
{
    display: none;
}


a.neutral-link{
    color:var(--color-black)!important;
}

a.neutral-link:hover{
    color:var(--primary-color)!important;
    text-decoration: underline!important;
}
