.cardContainer{
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.notificationBox{
    width: 350px;
    background-color: #fff;
    box-shadow: var(--box-shadow-3);
    border-radius: 4px;
    padding: 25px 15px;
}
.notificationBox.modalNotificationBox{
    width: 100%;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
}

.boxHeader{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom:1px dashed var(--color-dark-grey);
    position: relative;
    padding-bottom: 20px;
    text-align: center;
}

.boxHeader h4 svg{
    height: 70px !important;
}


.boxHeader.success h4 svg,.boxHeader.success h4{
    color: var(--primary-color);
}

.boxHeader.fail h4 svg,.boxHeader.fail h4{
    color: red;
}



.boxHeader h6{
    color: var(--color-dark-grey);
    font-size: 14px;
}

.confirmationBoxHeader.boxHeader::before, .confirmationBoxHeader.boxHeader::after{
    content: " ";
    position: absolute;
    bottom: -10px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #e6e6e6;
}
.confirmationBoxHeader.boxHeader::before{
    left:-25px;
}

.confirmationBoxHeader.boxHeader::after{
    right:-25px;
}
.boxBody{
    padding: 20px 0;
}

.boxRow{
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.boxRow p{
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
}
.boxRow .label{
    color: #444;
}

.boxRow .value{
    color: var(--color-dark-grey);
    font-weight: 600;
}